const blankRoutes = [

    // {
    //     path: 'interface',
    //     name: 'interface',
    //     component: () => import('../views/systemAdmin/Interface.vue'),
    //     meta: {
    //         title: 'Banner Insite - Interface',
    //         permissions: ['system_admin']
    //     },
    //     sensitive: true,
    //     strict: true
    // }
    
]

export default blankRoutes